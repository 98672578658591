import { App } from '../../../shared/js/register.js';
import { start } from '../../../shared/js/init.js';

import translations from './translations.json';
import '../../../shared/js/phones.js';

document.addEventListener("DOMContentLoaded", function () {
    window.Form = new App({

        noResults: "\u0421\u043E\u0432\u043F\u0430\u0434\u0435\u043D\u0438\u0439 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E",
        messages: {
            required: "\u0417\u0430\u043F\u043E\u043B\u043D\u0438\u0442\u0435 \u043F\u043E\u043B\u0435",
            phone: {
                incorrect: "\u041D\u0435\u043A\u043A\u043E\u0440\u0435\u043A\u0442\u043D\u044B\u0439 \u043D\u043E\u043C\u0435\u0440 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0430",
            },
            email: {
                incorrect: "\u0410\u0434\u0440\u0435\u0441 \u0432 \u043D\u0435\u043F\u0440\u0430\u0432\u0438\u043B\u044C\u043D\u043E\u043C \u0444\u043E\u0440\u043C\u0430\u0442\u0435",
                symbolNotFound: '\u0410\u0434\u0440\u0435\u0441 \u0434\u043E\u043B\u0436\u0435\u043D \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0442\u044C \u0441\u0438\u043C\u0432\u043E\u043B \u201C@\u201C. \u0412 \u0430\u0434\u0440\u0435\u0441\u0435 \u043E\u0442\u0441\u0443\u0442\u0441\u0442\u0432\u0443\u0435\u0442 \u0441\u0438\u043C\u0432\u043E\u043B "@"',
                addressIsNotFull: "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0447\u0430\u0441\u0442\u044C \u0430\u0434\u0440\u0435\u0441\u0430 \u043F\u043E\u0441\u043B\u0435 \u0441\u0438\u043C\u0432\u043E\u043B\u0430 \u201C@\u201C. \u0410\u0434\u0440\u0435\u0441 \u043D\u0435 \u043F\u043E\u043B\u043D\u044B\u0439",
            },
            password: {
                minLength: "\u041C\u0438\u043D\u0438\u043C\u0430\u043B\u044C\u043D\u043E\u0435 \u0434\u043E\u043F\u0443\u0441\u0442\u0438\u043C\u043E\u0435 \u043A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u0441\u0438\u043C\u0432\u043E\u043B\u043E\u0432: 6. \u0421\u0435\u0439\u0447\u0430\u0441: %current_length%",
            },
            repeatPassword: {
                discrepancy: "\u0412\u0432\u0435\u0434\u0435\u043D\u043D\u044B\u0435 \u043F\u0430\u0440\u043E\u043B\u0438 \u043D\u0435 \u0441\u043E\u0432\u043F\u0430\u0434\u0430\u044E\u0442",
            },
            ofertaAgreement: {
                notChecked: "\u041F\u043E\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u0435 \u0441\u0432\u043E\u0435 \u0441\u043E\u0433\u043B\u0430\u0441\u0438\u0435 \u0441 \u043F\u0440\u0430\u0432\u0438\u043B\u0430\u043C\u0438",
            },
        },

        options: {
            regType: "phone",
            bonusType: "sport",

            tabButtonClass: ".tab",
            formEmailClass: ".form-email",
            formPhoneClass: ".form-phone",
            buttonActionClass: ".button-action",
            emailInputClass: ".email-input",
            passwordInputClass: ".password-input",
            passwordInput2Class: ".password-input2",
            phoneInputClass: ".phone-input",
            emailCountry: ".email-country",
            phonePrefixSelect: ".phone-prefix__select",
            phonePrefixCode: ".phone-prefix__code",
            phoneCurrency: ".phone-currency",
            emailCurrency: ".email-currency",
            phonePrefixFlag: ".phone-prefix__flag",

            emailErrorClass: ".email-error",
            passwordErrorClass: ".password-error",
            password2ErrorClass: ".password2-error",
            phoneErrorClass: ".phone-error",
            ofertaAgreementInputId: "#oferta-agreement",
        },
        
    });

    start({ phones: window.phones, translations: translations });
});